import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, InfoIcon, WarningIcon, Tabs } from '../components';
import { newAccountRaw, newAccountCurl, newAccountJava, newAccountJavascript, newAccountNode, newAccountPerl, newAccountPython, newAccountPhp, newAccountRuby, newAccountGo, newAccountC, newAccountVB, newAccountGroovy, newAccountObjectiveC, newAccountSwift } from 'requestExamples/user-accounts';
export const endpoints = [{
  param: 'POST',
  value: '/auto/snew'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "create-a-new-account"
    }}>{`Create a new account`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			You can have your application automatically create accounts for your clients in SmartVault.
			<br />
			This saves them the extra step of having to go through the sign up process themselves.
			<br />
			<br />
			After adding the account, if everything went well, the email specified will receive an email as confirmation
			of the new account created.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			Remember you'll need to use the <strong>client token</strong> while using the create new account endpoint. Also,
			the info you add while the user is logged in will be wiped out once the user signs out of the account.
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Expander title="body params" mdxType="Expander">
	<Attributes items={[{
        name: 'email',
        type: 'string',
        description: 'The email used to identify the user'
      }, {
        name: 'first_name',
        type: 'string (optional)',
        description: 'The first name of the user. If not provided, the user will be prompted to provide it later.'
      }, {
        name: 'last_name',
        type: 'string (optional)',
        description: 'The last name of the user. If not provided, the user will be prompted to provide it later.'
      }, {
        name: 'account_name',
        type: 'string (optional)',
        description: "The name of the account. If not provided, it defaults to the user's email address. We recommend using something like the organization/company name if available."
      }, {
        name: 'create_vault',
        type: 'boolean (optional)',
        description: 'Sets whether or not a vault should be created under the account. Vaults are the containers for files and folders. Default is false.'
      }, {
        name: 'vault_name',
        type: 'string (optional)',
        description: 'The name of the vault to create if "create_vault" is true. Defaults to "My First Vault".'
      }, {
        name: 'create_folder',
        type: 'boolean (optional)',
        description: 'Sets whether or not a folder should be created if a vault if created. Defaults to false.'
      }, {
        name: 'folder_name',
        type: 'string (optional)',
        description: 'The name of the folder to create if create_vault and create_folder are true. Defaults to "My First Folder".'
      }, {
        name: 'phone_number',
        type: 'string (optional)',
        description: 'The phone number of the user. If not provided, the user will be prompted to provide it later.'
      }, {
        name: 'email_validated',
        type: 'boolean (optional)',
        description: 'Set this to true if your client has already verified that the user has possession of the email address. Defaults to false.'
      }]} mdxType="Attributes" />
	<InfoIcon mdxType="InfoIcon" />
	Please note that if you set this to true without previously validating the user's email address, you are lowering
	the security of the SmartVault system and are in violation of the{' '}
	<a href="https://www.smartvault.com/terms-of-service/">SmartVault Terms of Use</a>
	.
	<br />
	<br />
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: newAccountRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: newAccountCurl
    }, {
      tabTitle: 'Java',
      tabInfo: newAccountJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: newAccountJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: newAccountNode
    }, {
      tabTitle: 'Perl',
      tabInfo: newAccountPerl
    }, {
      tabTitle: 'Python',
      tabInfo: newAccountPython
    }, {
      tabTitle: 'PHP',
      tabInfo: newAccountPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: newAccountRuby
    }, {
      tabTitle: 'Go',
      tabInfo: newAccountGo
    }, {
      tabTitle: 'C#',
      tabInfo: newAccountC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: newAccountVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: newAccountGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: newAccountObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: newAccountSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns the user_id, account_name and account_id of the newly created account.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "user_id": "User:bmVNadE3tUu53EAecE_6AQ",
        "account_name": "sallysfirm",
        "account_id": "OrganizationalUnit:Kr3C85PQqki0Y70KPcwCtQ"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the account name specified on the request body already exists.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -1593835510,
        "error_text": "Object already exists.",
        "instance_guid": "fb51a10f-1f20-4640-8bac-bb6f431d1e20",
        "status_code": 500
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      